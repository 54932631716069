import {MDXProvider} from '@mdx-js/react';
import Footer from 'pages/layouts/Footer';
import PlausibleProvider from 'next-plausible';
import {DefaultSeo} from 'next-seo';
import type {AppProps} from 'next/app';
import Code from '../components/Code';
import Navbar from './layouts/Navbar';
import {NextQueryParamProvider} from 'next-query-params';

import '@fontsource/open-sans';
import './App.scss';
import './globals.scss';

const components = {
  code: Code,
};

function MyApp({Component, pageProps}: AppProps) {
  return (
    <NextQueryParamProvider>
      <MDXProvider components={components}>
        <PlausibleProvider domain="polarsignals.com">
          <DefaultSeo
            title="Polar Signals"
            description="Performance insight for everyone."
            openGraph={{
              type: 'website',
              locale: 'en_IE',
              url: 'https://polarsignals.com/',
              site_name: 'Polar Signals',
              description: 'Performance insight for everyone.',
              title: 'Polar Signals',
              images: [{url: 'https://polarsignals.com/og-image.png'}],
            }}
            twitter={{
              handle: '@PolarSignalsIO',
              site: '@PolarSignalsIO',
              cardType: 'summary_large_image',
            }}
            additionalLinkTags={[
              {rel: 'icon', href: '/favicon.png'},
              {
                rel: 'alternate',
                type: 'application/rss+xml',
                href: 'https://www.polarsignals.com/rss.xml',
              },
            ]}
          />
          <Navbar />

          <Component {...pageProps} />

          <Footer />
        </PlausibleProvider>
      </MDXProvider>
    </NextQueryParamProvider>
  );
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext: AppContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);

//   return { ...appProps }
// }

export default MyApp;
